html {
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
    height: 100%;
}

#root {
    height: 100%;
}